import React, { Component } from "react";
import dateformat from "dateformat";
import SmileyFaceDisplay from "./smileyFaceDisplay";
import * as localsentiments from "./util/localSentimentCalls";
import { getQualitativeScore } from "./util/utilityMethods";
import "./App.css";

class CommentLine extends Component {
  state = {
    score: this.props.score,
  };

  //callback for when we get a score
  setSentimentScore(score, t) {
    const sentimentResults = score;
    let curScore = 0;
    if (
      sentimentResults.analysis != null &&
      sentimentResults.analysis.comparative != null &&
      sentimentResults.analysis !== "undefined"
    ) {
      curScore = 50 * sentimentResults.analysis.comparative;
      console.log("curscore for text was ", curScore);
    } else {
      console.log(
        "could not get sentiment data. got this as raw result:",
        sentimentResults
      );
    }
    t.setState({ score: curScore });
    t.props.updateAverage(curScore);
  }

  getSentimentForText() {
    localsentiments.fetchSentimentInfo(
      this.props.comment,
      this.setSentimentScore,
      this
    );
  }

  componentDidMount() {
    this.getSentimentForText();
  }

  render() {
    const createdString = dateformat(this.props.created, "yyyy-mm-dd hh:MM");
    //const qualitativeSentiment = this.state.score;
    const qualitativeSentiment = getQualitativeScore(this.state.score);
    console.log("what is the score?", this.state.score);
    console.log("what is the qualitative sentiment?", this.state.score);
    return (
      <div className="commentLine">
        <div className="commentDateLine">{createdString}</div>
        <div className="commentText">&ldquo;{this.props.comment}&rdquo;</div>
        {this.state.score === "loading" ? (
          <div className="smileyBox">loading...</div>
        ) : (
          <div className="smileyBox">
            <SmileyFaceDisplay
              className="smallImage"
              score={this.state.score}
            />
            <div className="sentimentLabel">{qualitativeSentiment}</div>
          </div>
        )}
      </div>
    );
  }
}

export default CommentLine;
