import React, { Component } from 'react';
import './App.css';

class UserSelectForm extends Component {

  handleSubmit(event)
  {
    event.preventDefault(); // Let's stop this event.
    event.stopPropagation(); // Really this time.
    const data = new FormData(event.target);
    this.props.changeUserCallback(data.get('username'));
  }

  render() {
    return (
      <div className="userInputForm">
      <form onSubmit={this.props.changeUserCallback}>
        <input type="text" name="username" placeholder="Github username"/>
        <br/>
        <button>Let's Find Out!</button>
        </form>
      </div>
    );
  }
}

export default UserSelectForm;
