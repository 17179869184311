import React, { Component } from 'react';
import './App.css';

class SmileyFaceDisplay extends Component 
{

  pickSmiley(score)
  {
    let imgName = '';
    if(isNaN(score))
    {
      imgName = 'imgs/spinny.gif';
    }else if(score < 0)
    {
      if(score < -7) {
        score = -7;
      }
      imgName = 'imgs/n' + Math.abs(Math.round(score)) + '.png';
    }else{
      if(score > 6) {
        score = 6;
      }
      imgName = 'imgs/' + Math.abs(Math.round(score)) + '.png';

    }
    return imgName;
  }

  render() {
    const altTag = `Smiley Face:${this.props.score}`;

    return (
      <div className="smileyContainer">
        <img alt={altTag} className={this.props.className} src={ this.pickSmiley(this.props.score) }></img>
      </div>
    );
  }
}

export default SmileyFaceDisplay;
