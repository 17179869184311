import React, { Component } from "react";
//import Sentiment from "sentiment";
import UserSelectForm from "./UserSelect";
import CommentLine from "./CommentLine";
import SmileyFaceDisplay from "./smileyFaceDisplay";
import { localConfig } from "./config";
import * as gh from "./util/githubCalls";
import * as utils from "./util/utilityMethods";

//import {eventsResponse}  from './exampleEventResponse';

import "./App.css";

class App extends Component {
  state = {
    username: "",
    avatarImg: "",
    tabulatedItemsCount: 0,
    tabulatedScoreSum: 0,
    userFound: false,
    allComments: [],
  };

  //
  componentDidMount() {
    this.setState({ tabulatedScoreSum: 0, tabulatedItemsCount: 0 });
    if (localConfig.init_with_example_json) {
      this.setStateToHardcodedExample();
    }
  }
  setStateToHardcodedExample() {
    /* const userComments = utils.getCommentsFromEvents(eventsResponse);
    console.log('Using hardcoded comments: ',userComments);
    this.setState({ allComments: userComments, userFound: true, username: 'kinstelli' });*/
  }

  handleUsernameUpdate() {
    this.setState({ tabulatedScoreSum: 0, tabulatedItemsCount: 0 });

    this.loadTheUsersComments();
    this.getUserAvatar();
  }

  loadTheUsersComments() {
    let wasUserFound = false;
    const curUser = this.state.username;
    this.setState({
      allComments: [{ comment: "hi" }, { comment: "hello" }],
      userFound: true,
    });
    gh.getUserEvents(curUser)
      .then((res) => res.json())
      .then((userResult) => {
        if (
          userResult.hasOwnProperty("message") &&
          userResult.message === "Not Found"
        ) {
          console.log("User wasnt found.");
          wasUserFound = false;
        } else {
          wasUserFound = true;
        }

        const userComments = utils.getCommentsFromEvents(userResult);
        this.setState({ allComments: userComments, userFound: wasUserFound });
      });
  }

  getUserAvatar() {
    const curUser = this.state.username;
    gh.getUserInfo(curUser)
      .then((res) => res.json())
      .then((body) => {
       // console.log("User info: ", body);
       // console.log("avatar url: ", body.avatar_url);
        this.setState({ avatarImg: body.avatar_url });
        if (body.message && body.message.includes("rate limit exceeded")) {
          this.setState({ username: "rate exception" });
        }
      });
  }

  updateAverage(score) {
    //get current count of items in array...
    let newItemsCount = this.state.tabulatedItemsCount + 1;
    let newScoreSum = score + this.state.tabulatedScoreSum;
    this.setState({
      tabulatedItemsCount: newItemsCount,
      tabulatedScoreSum: newScoreSum,
    });
  }

  changeUser(event) {
    event.preventDefault(); // Let's stop this event.
    //clear state
    this.setState(
      { username: "...", allComments: [], userFound: false },
      this.handleUsernameUpdate
    );
    const data = new FormData(event.target);
    //TODO: refactor to use lifecycle events instead of callback
    this.setState(
      { username: data.get("username") },
      this.handleUsernameUpdate
    );
  }

  render() {
    //console.log("did we get an image? ", this.state.avatarImg);

    let allComments = this.state.allComments.map((eachComment) => {
      return (
        <CommentLine
          key={eachComment.created_at}
          comment={eachComment.body}
          created={eachComment.created_at}
          score="loading"
          updateAverage={(score) => this.updateAverage(score)}
        />
      );
    });

    const userSentimentAverage = this.state.allComments.length
      ? Math.round(
          this.state.tabulatedScoreSum / this.state.tabulatedItemsCount
        )
      : "loading";

    //handle various edge cases:
    let exceptionOutput = false;
    if (this.state.username === "") {
      exceptionOutput = `No user entered.`;
    } else if (this.state.username === "rate exception") {
      exceptionOutput = `You've made too many requests! Try again in a few minutes.`;
    } else if (this.state.username === "...") {
      exceptionOutput = `Loading...`;
    } else if (!this.state.userFound) {
      exceptionOutput = `User ${this.state.username} was not found.`;
    } else if (this.state.allComments.length < 1) {
      exceptionOutput = `No recent public comments found from ${
        this.state.username
      }.`;
    }

    return (
      <div className="App">
        <div className="headingBox">
          How friendly is this Github user?
          <UserSelectForm
            changeUserCallback={(newUser) => this.changeUser(newUser)}
          />
        </div>

        {exceptionOutput ? (
          <div>
            <div className="userImagesBlock">
              <img
                className="centeredAvatar"
                src={this.state.avatarImg}
                alt=""
              />
            </div>
            <div>{exceptionOutput}</div>
          </div>
        ) : (
          <div>
            <div className="userImagesBlock">
              <img
                className="avatarStyling"
                src={this.state.avatarImg}
                alt="user avatar"
              />
              <SmileyFaceDisplay
                className="largeImage"
                score={userSentimentAverage}
              />
            </div>
            <div className="verdictLine">
              {this.state.username} is{" "}
              {utils.getQualitativeScore(userSentimentAverage)}
              <div className="avgSentimentLine">
                Average sentiment score: {userSentimentAverage}
              </div>
            </div>
            <div className="allCommentsContainer">
              Lets see what they have to say....
              {allComments}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default App;
