import { localConfig } from "../config";

const githubApiBaseUrl = `${localConfig.github_api.protocol}${
  localConfig.github_api.host
}`;

const usersInfoUrl = `${githubApiBaseUrl}/users`;
const orgsInfoUrl = `${githubApiBaseUrl}/orgs`;

// GET /users/:username
export const getUserInfo = (username) => {
  // do a fetch
  return fetch(`${usersInfoUrl}/${username}`);
};

export const getUserRepos = (username) => {
  // do a fetch
  fetch(`${usersInfoUrl}/${username}/repos`)
    .then((res) => res.text())
    .then((body) => console.log(body));
};

// /orgs/:org/public_members
export const getMembersFromAnOrg = (org) => {
  // do a fetch
  fetch(`${orgsInfoUrl}/${org}/public_members`)
    .then((res) => res.text())
    .then((body) => console.log(body));
};

export const getUserEvents = (username) => {
  // https://api.github.com/users/{username}/events
  const eventsUrl = `${usersInfoUrl}/${username}/events`;
  return fetch(eventsUrl);
};
