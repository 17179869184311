// @param - `eventsObject` is raw data from Github
// this method parses that object, finding all events with a message body

export const getCommentsFromEvents = (eventsObject) => {
  if (!(eventsObject instanceof Array)) {
    return [];
  }
  //just getting events with text
  const userComments = eventsObject
    .filter((anEvent) => {
      return (
        anEvent.hasOwnProperty("payload") &&
        (anEvent.payload.hasOwnProperty("comment") ||
          anEvent.type === "PullRequestEvent")
      );
    })
    .map((commentEvent) => {
      const commentData = {};
      // detecting and coalescing different object types into simpler object format...
      if (commentEvent.payload.hasOwnProperty("comment")) {
        commentData.body = commentEvent.payload.comment.body;
        commentData.created_at = commentEvent.payload.comment.created_at;
      } else {
        commentData.body = commentEvent.payload.pull_request.body;
        commentData.created_at = commentEvent.payload.pull_request.updated_at;
      }

      return commentData;
    });
  return userComments;
};

export const getQualitativeScore = (score) => {
  console.log("quantitative score:", score);

  let normalizedNum = Math.floor(score * 1.2);
  //trim limits
  if (normalizedNum < -7) {
    normalizedNum = 7;
  }
  if (normalizedNum > 6) {
    normalizedNum = 6;
  }
  console.log(
    "normalizedNum is ",
    normalizedNum,
    " or ",
    normalizedNum + 7,
    "with offset, for score ",
    score
  );

  //ranking -3 to

  // 0 - 13 -> integer options
  const scoreToWords = [
    "Basically horrible", // 0
    "Vicious", // 1
    "Mean", // 2
    "Rude", // 3
    "Cranky", // 4
    "Not Nice", // 5
    "Unpleasant", // 6
    "Neutral", // 7
    "Pleasant", // 8
    "Nice", // 9
    "Amiable", // 10
    "Friendly", // 11
    "Very Friendly", // 12
    "Extremely Friendly", // 13
  ];

  return scoreToWords[normalizedNum + 7];
};
