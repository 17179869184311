import { localConfig } from "../config";
import axios from "axios";

const headerObj = new Headers({
  "Content-Type": "application/json",
  Accept: "text/json, whatever/ok",
  "Sec-Fetch-Mode": "cors",
});

export const fetchSentimentInfo = (givenText, callback, t) => {
  return fetch(`https://api.devniceness.com/analyze`, {
    method: "POST",
    headers: headerObj,
    body: JSON.stringify({ text: givenText }),
  })
    .catch((error) => {
      console.log(error);
    })
    .then(function(response) {
      // The response is a Response instance.
      // You parse the data into a useable format using `.json()`
      return response.json();
    })
    .then(function(data) {
      console.log("data...", data);
      callback(data, t);
    });

  // return axios.post(
  //   "https://api.devniceness.com/analyze",
  //   {
  //     text: givenText,
  //   },
  //   {
  //     headers: headerObj,
  //   }
  // );
};
